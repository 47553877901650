<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：学会管理 > <b>审批管理</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="personnel-list">
            <el-table
                :data="tableData"
                header-row-class-name="table-header"
                style="width: 100%"
            >
                <el-table-column label="角色">
                    <template slot-scope="scope">
                        <div class="table-item">
                            {{ scope.row.type_name }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="审批人">
                    <template slot-scope="scope">
                        <div class="table-item">
                            {{ scope.row.true_name ? scope.row.true_name : "--" }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" prop="operate" width="350">
                    <template slot-scope="scope">
                        <div class="table-item">
                            <el-button
                                v-if="scope.row.true_name"
                                type="text"
                                @click="showEditDialog(scope.row)"
                            >修改
                            </el-button
                            >
                            <el-button
                                v-if="!scope.row.true_name"
                                type="text"
                                @click="showEditDialog(scope.row)"
                            >添加
                            </el-button
                            >
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="选择人员">
            <el-collapse>
                <el-collapse-item
                    v-for="item in members"
                    :key="item.id"
                    :disabled="item.son.length == 0"
                    :name="item.id"
                >
                    <template slot="title">
                        {{ item.name }}（{{ item.son.length }}人）
                    </template>
                    <div class="dialog-members">
                        <div
                            v-for="member in item.son"
                            :key="member.id"
                            class="master-name dialog-members-name"
                        >
              <span @click="selectMember(member)">
                {{
                      member.true_name | sub_name
                  }}
                <i
                    :class="{ active: member.check }"
                    class="el-icon-success"
                ></i>
              </span>
                            <div>{{ member.true_name }}</div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>

            <span slot="footer" class="dialog-footer">
          <div class="dialog-container flex-center">
          <el-input v-model="edit_code" placeholder="请输入验证码" type="number"></el-input>
          <el-button class="margin-left margin-right" type="primary" @click="sendCode">{{ codeText }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subMember">确 定</el-button>
        </div>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import {sub_name} from "../../tools";

export default {
    name: "level",
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            members: [],
            type: 0,
            id: null, // 计时器id
            timeId: null, // 一次性计时器id
            codeText: '获取验证码', // 验证码文字
            edit_code: "",
        };
    },
    created() {
        this.$emit("setIndex", [2, 3]);
        this.init();
    },
    methods: {
        //  获取验证码
        sendCode() {
            if (this.codeText === '获取验证码') {
                this.$api.apiContent.SendMsg({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                    }, this.$store.state.user.key)
                }).then(() => {
                    this.codeText = 60
                    this.id = setInterval(() => {
                        if (this.codeText === 0) {
                            this.codeText = '获取验证码'
                            return
                        }
                        if (typeof this.codeText === 'number') {
                            this.codeText--
                        }
                    }, 1000)
                }).catch(e => {
                    this.$message.error(e.msg)
                    this.codeText = '获取验证码'
                    clearInterval(this.id)
                })
            } else {
                this.$message.error('请勿重复发送')
            }
        },
        showEditDialog(data) {
            this.dialogVisible = true;
            this.type = data.type;
            this.members.map((member) => {
                member.son.map((son) => {
                    son.check = son.user_id === data.user_id;
                });
            });
        },
        closeEdit() {
            this.dialogVisible = false;
            this.edit_code = "";
        },
        subMember() {
            let approvalMembers = "";
            this.members.map((member) => {
                member.son.map((son) => {
                    if (son.check) {
                        approvalMembers = son;
                    }
                });
            });
            if (!approvalMembers) {
                this.$message.error("请选择审批人");
                return;
            }
            if (!this.edit_code) {
                this.$message.error("请输入验证码")
                return
            }
            this.$api.apiContent
                .approvalOperation({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: this.type,
                    code: this.edit_code,
                    user_id: approvalMembers.user_id,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: this.type,
                            code: this.edit_code,
                            user_id: approvalMembers.user_id,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeEdit();
                    this.init();
                    this.$message.success(res.msg);
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        //  选择项目管理人
        selectMember(item) {
            this.members.map((member) => {
                member.son.map((son) => {
                    son.check = son.user_id === item.user_id;
                });
            });
            this.$forceUpdate();
        },
        //  页面加载时请求
        init() {
            this.$api.apiContent
                .approvalSysList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.approval.type = 2;
                    res.data.leader.type = 1;
                    res.data.finance.type = 0;
                    res.data.counterpart.type = 3;
                    res.data.president.type = 4;
                    let arr = [
                        res.data.approval,
                        res.data.leader,
                        res.data.finance,
                        res.data.counterpart,
                        res.data.president,
                    ];
                    this.tableData = arr;
                })
                .catch(() => {
                    this.tableData = [];
                });
            //  获取人员列表
            this.$api.apiContent
                .getAllUser({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.members = res.data;
                    this.members.map((item) => {
                        item.son.map((items) => {
                            items.check = false; //  是否被选择
                        });
                    });
                })
                .catch(() => {
                    this.members = [];
                });
        },
    },
    filters: {
        sub_name(str) {
            return sub_name(str)
        },
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .dialog-members {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 30px;
        border-top: 1px solid #f0f2f5;

        .dialog-members-name {
            margin-left: 20px;

            span {
                cursor: pointer;
                position: relative;

                i {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    color: #f0f2f5;
                    font-size: 26px;
                    width: 24px;
                    height: 24px;
                    border-radius: 25px;
                    background: white;
                    overflow: hidden;

                    &.active {
                        color: #13ce66;
                        background: white;
                    }
                }
            }

            .active {
                background: #666;
            }
        }
    }

    .approval-member-list {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &:before {
            position: absolute;
            background: #3b77e7;
            border-radius: 13px;
            width: 1px;
            top: 56px;
            bottom: 56px;
            left: 16px;
            content: "";
            z-index: 1;
        }

        .approval-member {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #f0f0f0;
            padding: 20px;

            &:after {
                position: absolute;
                background: #3b77e7;
                border-radius: 13px;
                width: 13px;
                height: 13px;
                top: 50%;
                transform: translate(0, -50%);
                left: 10px;
                content: "";
            }

            .approval-member-name {
                font-size: 20px;
                color: #000000;
                margin-left: 30px;

                .approval-member-num {
                    font-size: 16px;
                    color: #999999;
                    margin-top: 12px;
                }
            }

            .approval-members {
                display: flex;
                flex-direction: row;

                .master-name-line {
                    padding: 0 25px;
                    font-size: 16px;
                    color: #3b77e7;
                    align-self: center;
                    margin-bottom: 18px;
                }

                .master-name-add {
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;

                    > div {
                        font-size: 12px;
                        color: white;
                        text-align: center;
                        margin-top: 5px;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px dotted #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 36px;
                        color: #3b77e7;
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .master-name {
        position: relative;
        display: flex;
        flex-direction: column;

        > div {
            font-size: 12px;
            color: #999999;
            text-align: center;
            margin-top: 5px;
        }

        .close {
            position: absolute;
            color: red;
            font-size: 26px;
            right: -10px;
            top: -5px;
            cursor: pointer;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 56px;
            height: 56px;
            border-radius: 56px;
            text-align: center;
            cursor: pointer;
            /*line-height: 24px;*/
            font-size: 18px;
            color: #fff;
            background-color: #3b77e7;
        }
    }

    .master-name-change {
        color: #3b77e7;
        margin-left: 20px;
    }

    .personnel-list {
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        overflow: hidden;
        padding: 34px 28px;
        min-height: 700px;

        .table-item {
            text-align: center;
            font-size: 16px;
            color: #666666;
        }
    }
}
</style>
